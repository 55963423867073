import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProfileCollection} from 'src/app/core/collection';
import {ProfileHelper} from 'src/app/core/helpers';
import {Profile} from 'src/app/core/models';
import {ProfileTO} from 'src/app/core/to';

@Injectable()
export class ProfileService
{
  constructor(private profileCollection: ProfileCollection)
  {
  }

  getCurrent(): Observable<ProfileTO>
  {
    return this.profileCollection.cacheGet('current').pipe(map(profile => ProfileHelper.toProfileTO(profile)));
  }

  update(profile: ProfileTO): Observable<Profile>
  {
    return this.profileCollection.updateCurrent(ProfileHelper.toProfile(profile));
  }
}
