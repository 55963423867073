import {BaseEntity} from 'src/app/core/models/base-entity.model';

export class Attachment extends BaseEntity
{
  filename: string;
  mimeType: string;
  checksum: string;
  version: number;
  sizeInBytes: number;
}
