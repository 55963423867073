import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SupportedMessageTypeCollection} from 'src/app/core/collection';
import {SupportedMessageType, UpdateSupportedMessageType} from 'src/app/core/models';
import {PartyType} from 'src/app/filings/shared/components/base-message-type-and-receiver/party-type.enum';

@Injectable()
export class SupportedMessageTypeService
{
  constructor(private supportedMessageTypeCollection: SupportedMessageTypeCollection)
  {
  }

  list(): Observable<SupportedMessageType[]>
  {
    return this.supportedMessageTypeCollection.listAll();
  }

  togglePartyTypeStatus(messageTypeName: string, partyType: PartyType, active: boolean): Observable<void>
  {
    const supportedMessageType = new UpdateSupportedMessageType();
    supportedMessageType.name = messageTypeName;
    supportedMessageType.partyType = SupportedMessageTypeService.getKeyPartyType(partyType);
    supportedMessageType.active = active;

    return this.supportedMessageTypeCollection.togglePartyTypeStatus(supportedMessageType).pipe(
      tap(() => this.list())
    );
  }

  startMessageTypesSynchronization(): Observable<void>
  {
      return this.supportedMessageTypeCollection.startMessageTypesSynchronization();
  }

  private static getKeyPartyType(value: string)
  {
    const indexOfS = Object.values(PartyType).indexOf(value as unknown as PartyType);

    const key = Object.keys(PartyType)[indexOfS];

    return key;
  }
}
