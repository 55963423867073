import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {ErrorHandler} from '../error-handler';
import {Settings} from '../models';
import {BaseCollection} from './base.collection';
import {headers} from './collection.helper';

@Injectable()
export class SettingsCollection
{
  private cache: BehaviorSubject<Settings | undefined>;
  private readonly url = `${BaseCollection.URL_PREFIX}/settings`;

  constructor(private http: HttpClient, protected errorHandler: ErrorHandler)
  {
    this.cache = new BehaviorSubject<Settings | undefined>(undefined);
  }

  cacheGet(): Observable<Settings>
  {
    if (this.cache.getValue() === undefined)
    {
      this.http.get<Settings>(this.url, { headers: headers()})
        .pipe(catchError(err => this.errorHandler.handle<Settings>(err)))
        .subscribe(value => this.cache.next(value));
    }

    // @ts-ignore
    return this.cache.asObservable().pipe(filter(val => !!val));
  }
}
