import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Receiver, ReceiverSbvRequestConfiguration} from 'src/app/core/models';
import {ReceiverSbvRequestConfigurationCollection} from 'src/app/core/collection/receiver-sbv-request-configuration.collection';
import {AvailableSbvRequestConsentTypesCollection} from 'src/app/core/collection/available-sbv-request-consent-types.collection';
@Injectable()
export class ReceiverSbvRequestConfigurationService
{
  constructor(private receiverSbvRequestConfigurationCollection: ReceiverSbvRequestConfigurationCollection,
              private availableSbvRequestConsentTypesCollection: AvailableSbvRequestConsentTypesCollection)
  {
  }

  list(refresh: boolean): Observable<ReceiverSbvRequestConfiguration[]>
  {
    if (refresh)
    {
      return this.receiverSbvRequestConfigurationCollection.list();
    }
    else
    {
      return this.receiverSbvRequestConfigurationCollection.cacheList();
    }
  }

  listAvailableConsentTypes(): Observable<String[]>
  {
    return this.availableSbvRequestConsentTypesCollection.cacheList();
  }

  updateConsentType(receiverSbvRequestConfiguration: ReceiverSbvRequestConfiguration): Observable<ReceiverSbvRequestConfiguration>
  {
      let modifyFunction = receiverSbvRequestConfiguration.guid == '' ? this.receiverSbvRequestConfigurationCollection.create : this.receiverSbvRequestConfigurationCollection.update;
      return modifyFunction.call(this.receiverSbvRequestConfigurationCollection, receiverSbvRequestConfiguration)
        .pipe(
          tap(() => this.receiverSbvRequestConfigurationCollection.list().subscribe())
        );
  }

  deleteConsentType(receiver: Receiver): Observable<void>
  {
    return this.receiverSbvRequestConfigurationCollection.delete(receiver.guid)
      .pipe(
        tap(() => this.receiverSbvRequestConfigurationCollection.list().subscribe())
      );
  }
}
