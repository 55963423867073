import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SimpleCacheCollection} from 'src/app/core/collection/simplecache.collection';
import {ErrorHandler} from 'src/app/core/error-handler';

@Injectable()
export class AvailableSbvRequestConsentTypesCollection extends SimpleCacheCollection<string>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
      super(http, errorHandler, 'receiver-sbv-request-configuration/available-consent-types');
  }
}
