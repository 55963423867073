import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from 'angular2-notifications';

@Injectable()
export class NotificationService
{
  constructor(private delegate: NotificationsService, private translateService: TranslateService)
  {
  }

  serverError(error: any)
  {
    let message: string;

    if (error?.code)
    {
      const key = `server.error.${error.code}`;
      message = this.translateService.instant(key);

      if (message === key)
      {
        message = this.translateService.instant('server.error.UNKNOWN');
      }
    }
    else
    {
      message = this.translateService.instant('server.error.UNKNOWN');
    }
    return this.delegate.error('', message);
  }

  error(message: string, title = '')
  {
    const translations = this.translateService.instant([title, message]);
    const header = translations[title] !== title ? translations[title] : '';
    return this.delegate.error(header, translations[message]);
  }

  success(message: string, title = '')
  {
    const translations = this.translateService.instant([title, message]);
    const header = translations[title] !== title ? translations[title] : '';
    return this.delegate.success(header, translations[message]);
  }
}
