<div class="main-menu">
    <a class="logo" routerLink="/dashboard">
        <img src="/assets/images/mdmb_logo_colorway_rgb.svg" alt="">
    </a>
    <div class="navigation-menu" [class.expanded]="menuExpanded">
        <ul class="list-menu">
            <li routerLink="/dashboard" routerLinkActive="selected" (click)="closeMenu()">{{'app.navigate.home' | translate}}</li>
            <li routerLink="/received-delivery/inbox" routerLinkActive="selected" (click)="closeMenu()">{{'app.navigate.report.received' | translate}}</li>
            <li routerLink="/filing/outbox" routerLinkActive="selected" (click)="closeMenu()">{{'app.navigate.report.sent' | translate}}</li>
            <li routerLink="/connect" routerLinkActive="selected" (click)="closeMenu()">{{'app.navigate.connect' | translate}}</li>
            <li routerLink="/admin" routerLinkActive="selected" hasAdmin (click)="closeMenu()">{{'app.navigate.admin' | translate}}</li>
        </ul>

        <button *ngIf="profile" mat-button class="menu-panel-button" [matMenuTriggerFor]="menu" disableRipple="true">
            <div fxLayout="column">
                <div>{{profile.fullName}}</div>
                <div *ngIf="profile.hasOrganization" class="organisation">{{profile.organizationName}}</div>
            </div>
            <i fxFlexAlign="center" class="fa fa-angle-down"></i>
        </button>
    </div>

    <div class="hamburger" [class.expanded]="menuExpanded" (click)="menuExpanded = !menuExpanded">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>

<mat-menu #menu="matMenu" xPosition="before">
    <button mat-menu-item (click)="logOut()" disableRipple="true">
        {{'app.navigate.userMenu.logout' | translate}}
    </button>
</mat-menu>
