import {KeycloakService} from 'keycloak-angular';
import {MSNInitializationService} from 'src/app/core/initialization/msn-initialization.service';
import {environment} from '../environments/environment';
import {AuthService} from './core';

export function authorizationInitializer(keycloak: KeycloakService, authService: AuthService): () => Promise<boolean>
{
  return (): Promise<boolean> =>
    authService.getAuthorizationConfiguration()
      .then(config => keycloak.init({config, initOptions: {checkLoginIframe: environment.production}, loadUserProfileAtStartUp: false}))
      // We ignore the errors, errors are thrown when the users declines the terms, if we don't ignore then we will get infinite loading icon
      .catch(error => false);
}

export function msnInitializer(msnInitializationService: MSNInitializationService): () => Promise<any>
{
  return async (): Promise<any> => msnInitializationService.load();
}
