import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {BaseEntity} from 'src/app/core/models';

export abstract class CacheCollection<T extends BaseEntity> extends BaseCollection<T>
{
  loading = false;
  private cacheCollection: BehaviorSubject<T[]>;
  private cacheMap: Map<string, BehaviorSubject<T | undefined>>;

  protected constructor(protected http: HttpClient, protected errorHandler: ErrorHandler, protected name: string)
  {
    super(http, errorHandler, name);
    this.cacheCollection = new BehaviorSubject<T[]>([]);
    this.cacheMap = new Map();
  }

  cacheList(reload: boolean = false): Observable<T[]>
  {
    if ((this.cacheCollection.getValue().length === 0 && !this.loading) || reload)
    {
      this.list().subscribe();
    }

    return this.cacheCollection.asObservable();
  }

  list(): Observable<T[]>
  {
    this.loading = true;
    return super.list()
      .pipe(
        tap(list => this.cacheCollection.next(list)),
        tap(() => this.loading = false),
      );
  }

  // ts-lint finds a lot of issues with (possible) null issues. for example: Map#get() returns <T|undefined> which isn't only T.
  cacheGet(guid: string): Observable<T>
  {
    const filterEntity = filter(val => !!val);

    if (this.cacheMap.has(guid))
    {
      // @ts-ignore
      return this.cacheMap.get(guid).asObservable().pipe(filterEntity);
    }

    const bs = new BehaviorSubject(undefined);
    this.cacheMap.set(guid, bs);

    // @ts-ignore
    this.getByGuid(guid).subscribe(entity => this.cacheMap.get(guid).next(entity));

    // @ts-ignore
    return bs.asObservable().pipe(filterEntity);
  }
}
