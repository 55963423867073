import {Component, Input} from '@angular/core';

@Component({
  selector   : 'msn-display-form-item',
  templateUrl: 'display-form-item.component.html'
})
export class DisplayFormItemComponent
{
  @Input() label: string;
  @Input() hasContent = true;
}
