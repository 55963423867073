import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard, HasAdminGuard} from 'src/app/core/authentication';
import {NavigationContainerComponent} from 'src/app/core/components/navigation-container/navigation-container.component';
import {BivPortalComponent} from 'src/app/landing/biv-portal.component';
import {OnboardingComponent} from 'src/app/onboarding/onboarding.component';
import {environment} from 'src/environments/environment';
import {RedirectGuard} from './core/redirect.guard';
import {HtmlRenderingDownloadPageComponent} from './filings/html-rendering-download-page/html-rendering-download-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'aanleverportaal',
    component: BivPortalComponent
  },
  {
    path: 'bpa',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.businessPerformanceApplicationLink
    }
  },
  {
    path: 'businessperformanceapplicatie',
    redirectTo: 'bpa'
  },
  {
    path: 'businessperformanceapplication',
    redirectTo: 'bpa'
  },
  {
    path: '', canActivate: [AuthenticationGuard], children: [
      {
        path: 'onboarding',
        component: OnboardingComponent,
      },
      {
        path: 'viewxbrlreport/:guid',
        component: HtmlRenderingDownloadPageComponent
      },
      {
        path: '', component: NavigationContainerComponent, children: [
          {
            path: 'dashboard',
            loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule),
          },
          {
            path: 'filing',
            loadChildren: () => import('./filings/filing.module').then(module => module.FilingModule),
          },
          {
            path: 'received-delivery',
            loadChildren: () => import('./received-delivery/received-delivery.module').then(module => module.ReceivedDeliveryModule),
          },
          {
            path: 'connect',
            loadChildren: () => import('./connect/connect.module').then(module => module.ConnectModule),
          },
          {
            path: 'admin',
            canActivate: [HasAdminGuard],
            loadChildren: () => import('./admin/admin.module').then(module => module.AdminModule),
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule
{
}
