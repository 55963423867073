export class ConfirmationData
{
  public constructor(public title: string,
                     public content: string,
                     public param: string,
                     public confirm: string,
                     public deleteAction: boolean = false)
  {
  }
}
