import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeNL from '@angular/common/locales/nl';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {BackendInterceptor} from 'src/app/core/backend.interceptor';
import {MSNInitializationService, MSNMissingTranslationHandler} from 'src/app/core/initialization';
import {BivPortalComponent} from 'src/app/landing/biv-portal.component';
import {OnboardingComponent} from 'src/app/onboarding/onboarding.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {environment} from 'src/environments/environment';
import {authorizationInitializer, msnInitializer} from './app-initializer';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './core';
import {CoreModule} from './core/core.module';

registerLocaleData(localeNL, 'nl');

@NgModule({
  declarations: [
    AppComponent,
    OnboardingComponent,
    BivPortalComponent,
  ],
  imports     : [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    KeycloakAngularModule,
    CoreModule,
    SharedModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MSNMissingTranslationHandler}
    })
  ],
  providers   : [
    {provide: APP_INITIALIZER, useFactory: authorizationInitializer, deps: [KeycloakService, AuthService], multi: true},
    {provide: APP_INITIALIZER, useFactory: msnInitializer, deps: [MSNInitializationService], multi: true},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    {provide: LOCALE_ID, useValue: environment.defaultLanguage},
    {provide: HTTP_INTERCEPTORS, useClass: BackendInterceptor, multi: true}
  ],
  bootstrap   : [AppComponent]
})
export class AppModule
{
}
