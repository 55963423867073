import {StatusType} from './status-type.model';

export class FilingStatus
{
  code: string;
  statusType: StatusType;
  description: string;
  timestamp: string;
  errorCode?: string;
  errorDescription?: string;
}
