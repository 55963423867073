import {EntryPoint} from 'src/app/core/models/entry-point.model';
import {VersionedBaseEntity} from './versioned-base-entity.model';

export class Report extends VersionedBaseEntity
{
  filename: string;
  checksum: string;
  cocNumber?: string;
  cocOrganizationName?: string;
  entryPoint: EntryPoint;
  sizeInBytes?: number;
}
