import {NgModule, Optional, SkipSelf} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {AuthService, MSNInitializationService, MSNTranslationLoader, ReportService, SettingService} from 'src/app/core';
import {AuthenticationGuard, HasAdminGuard} from 'src/app/core/authentication';
import {LogoComponent, MainMenuComponent} from 'src/app/core/components';
import {NavigationContainerComponent} from 'src/app/core/components/navigation-container/navigation-container.component';
import {NotificationService} from 'src/app/core/notifications/notification.service';
import {SharedModule} from 'src/app/shared/shared.module';
import {CollectionModule} from './collection/collection.module';
import {ErrorHandler} from './error-handler';
import {RedirectGuard} from './redirect.guard';
import {ProfileService} from './services';

@NgModule({
  imports     : [
    CollectionModule,
    SharedModule,
    TranslateModule,
  ],
  declarations: [
    LogoComponent,
    MainMenuComponent,
    NavigationContainerComponent,
  ],
  exports     : [
    LogoComponent,
    MainMenuComponent,
    NavigationContainerComponent,
  ],
  providers   : [
    AuthenticationGuard,
    HasAdminGuard,
    RedirectGuard,
    ErrorHandler,
    MSNInitializationService,
    MSNTranslationLoader,
    NotificationService,
    SettingService,
    ProfileService,
    AuthService,
    ReportService,
  ]
})
export class CoreModule
{
  constructor(@Optional() @SkipSelf() parentModule: CoreModule)
  {
    if (parentModule)
    {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
