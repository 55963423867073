import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import {environment} from 'src/environments/environment';

export class MSNMissingTranslationHandler implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams): any
  {
    if (!environment.production)
    {
      console.warn(`Translation missing: ${params.key}`);
    }
    return `${params.key}`;
  }
}
