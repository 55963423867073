import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ReportCollection} from 'src/app/core/collection';
import {Report} from 'src/app/core/models';
import {DownloadType} from 'src/app/core/models/download-type.model';

@Injectable()
export class ReportService
{
  constructor(private reportCollection: ReportCollection)
  {
  }

  get(guid: string): Observable<Report>
  {
    return this.reportCollection.getByGuid(guid);
  }

  updateReport(report: Report): Observable<Report>
  {
    return this.reportCollection.update(report);
  }

  downloadReport(guid: string, downloadType: DownloadType): Observable<any>
  {
    return this.reportCollection.downloadReport(guid, downloadType);
  }
}
