import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CacheCollection} from 'src/app/core/collection/cache.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {SupportedMessageType, UpdateSupportedMessageType} from 'src/app/core/models';
import {headers} from './collection.helper';

@Injectable()
export class SupportedMessageTypeCollection extends CacheCollection<SupportedMessageType>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'supported-message-types');
  }

  listAll(): Observable<SupportedMessageType[]>
  {
    return this.http.get<SupportedMessageType[]>(`${this.getBaseUrl()}`, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<SupportedMessageType[]>(err)));
  }

  togglePartyTypeStatus(object: UpdateSupportedMessageType): Observable<void>
  {
    const body = JSON.stringify(object);
    return this.http.put<any>(`${this.getBaseUrl()}`, body, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<void>(err)));
  }

  startMessageTypesSynchronization(): Observable<void>
  {
      return this.http.post<any>(`${this.getBaseUrl()}/startMessageTypesSynchronization`, null, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<void>(err)));
  }
}
