import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production)
{
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => transitionPreBootstrap())
  .catch(err => console.error(err));

function transitionPreBootstrap()
{
  const preBootstrap = document.getElementById('pre-bootstrap');
  const preBootstrapContainer = document.getElementById('pre-bootstrap-container');

  if (preBootstrap && preBootstrapContainer)
  {
    preBootstrap.setAttribute('class', 'loaded');
    setTimeout(() => preBootstrapContainer.remove(), 300); // change the timeout to be almost the same as the transition animation.
  }
}
