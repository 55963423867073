import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'firstLetterToLowerCase'})
export class FirstLetterToLoweCasePipe implements PipeTransform
{
  transform(value: string): string
  {
    return value
      ? value.charAt(0).toLowerCase() + value.slice(1)
      : value;
  }
}
