import {Profile} from '../models';
import {ProfileTO} from '../to';

export class ProfileHelper
{
  private constructor()
  {
  }

  public static toProfileTO(profile: Profile): ProfileTO
  {
    const {firstName, familyName, email, receiveUpdates, organization, firstTimeUser} = profile;
    const profileTO = new ProfileTO();
    profileTO.firstName = firstName;
    profileTO.familyName = familyName;
    profileTO.fullName = `${firstName} ${familyName}`;
    profileTO.email = email;
    profileTO.receiveUpdates = receiveUpdates;
    profileTO.hasOrganization = !!organization;
    profileTO.organizationName = organization?.name;
    profileTO.firstTimeUser = firstTimeUser;
    return profileTO;
  }

  public static toProfile(profileTO: ProfileTO): Profile
  {
    const profile = new Profile();
    profile.firstName = profileTO.firstName;
    profile.familyName = profileTO.familyName;
    profile.email = profileTO.email;
    profile.receiveUpdates = profileTO.receiveUpdates;
    profile.firstTimeUser = profileTO.firstTimeUser;
    return profile;
  }
}
