import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform
{
  transform(size: number, digits = 3, extension = 'MB')
  {
    return `${(size / (1024 * 1024)).toLocaleString(undefined, {maximumFractionDigits: digits})} ${extension}`;
  }
}
