<div class="shape-container">
    <div class="shapes-left">
        <img class="shape-item pos-1" src="/assets/images/shapes/shape1.svg" alt="">
        <img class="shape-item pos-2" src="/assets/images/shapes/shape2.svg" alt="">
        <img class="shape-item pos-3" src="/assets/images/shapes/shape3.svg" alt="">
        <img class="shape-item pos-4" src="/assets/images/shapes/shape4.svg" alt="">
        <img class="shape-item pos-5" src="/assets/images/shapes/shape2.svg" alt="">
        <img class="shape-item pos-6" src="/assets/images/shapes/shape3.svg" alt="">
        <img class="shape-item pos-7" src="/assets/images/shapes/shape7.svg" alt="">
    </div>

    <div class="shapes-right">
        <img class="shape-item pos-1" src="/assets/images/shapes/shape5.svg" alt="">
        <img class="shape-item pos-2" src="/assets/images/shapes/shape3.svg" alt="">
        <img class="shape-item pos-3" src="/assets/images/shapes/shape1.svg" alt="">
        <img class="shape-item pos-4" src="/assets/images/shapes/shape8.svg" alt="">
        <img class="shape-item pos-5" src="/assets/images/shapes/shape4.svg" alt="">
        <img class="shape-item pos-6" src="/assets/images/shapes/shape4.svg" alt="">
        <img class="shape-item pos-7" src="/assets/images/shapes/shape6.svg" alt="">
    </div>
</div>
