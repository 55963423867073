import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NEVER, Observable, throwError} from 'rxjs';
import {HTTP} from 'src/app/core/constants';
import {NotificationService} from 'src/app/core/notifications/notification.service';

@Injectable()
export class ErrorHandler
{
  constructor(private notificationService: NotificationService)
  {
  }

  handle<T>(error: HttpErrorResponse): Observable<T>
  {
    if (error.error instanceof ErrorEvent)
    {
      console.error('An error occurred in the frontend', error.error);
    }
    else if (error.error?.status === HTTP.UNAUTHORIZED)
    {
      return NEVER;
    }
    else
    {
      this.notificationService.serverError(error.error);
    }

    return throwError(error);
  }
}
