import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HTTP} from 'src/app/core/constants';

@Injectable()
export class BackendInterceptor implements HttpInterceptor
{
  constructor(private router: Router)
  {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    return next.handle(req).pipe(catchError(error => this.handleError(error)));
  }

  private handleError(error: any): Observable<never>
  {
    if (error instanceof HttpErrorResponse && error.status === HTTP.UNAUTHORIZED)
    {
      this.router.navigate(['/'], {skipLocationChange: true});
    }
    return throwError(error);
  }
}
