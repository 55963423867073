import {Application} from 'src/app/core/models/application.model';
import {BaseEntity} from 'src/app/core/models/base-entity.model';
import {Profile} from 'src/app/core/models/profile.model';

export class ApiKey extends BaseEntity
{
  guid: string;
  name: string;
  createdAt: string;
  createdBy: Profile;
  token: string;
  application?: Application;
}
