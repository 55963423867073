import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';

@Injectable()
export class HasAdminGuard implements CanActivate
{
  constructor(private keycloakService: KeycloakService, private router: Router)
  {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree
  {
    if (this.keycloakService.isUserInRole('admin'))
    {
      return true;
    }
    else
    {
      return this.router.parseUrl('/dashboard');
    }
  }
}
