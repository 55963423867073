<div class="onboarding-page" fxLayout="column" fxLayoutAlign="center center">
    <msn-logo></msn-logo>

    <div class="text-box" fxLayout="row wrap">
        <h3 fxFlex="100" class="header">
            {{'app.welcome.title' | translate}}
        </h3>
        <div fxLayout="row wrap" class="content">
            <p fxFlex="100" [innerHTML]="'app.landing.content' | translate: {eHerkenningSuppliersLink: eHerkenningSuppliersLink}"></p>
        </div>
    </div>

    <div fxLayout="row">
        <div fxFlex="50" class="corner-sling left-image">
        </div>
        <div fxFlex="50" class="corner-sling right-image">
        </div>
    </div>
</div>
