import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {DisplayFormItemComponent, MessageBoxComponent, PageHeaderComponent} from 'src/app/shared/components';
import {HasAdminDirective} from 'src/app/shared/directives/has-admin.directive';
import {MaterialModule} from 'src/app/shared/material.module';
import {FileSizePipe} from 'src/app/shared/pipes/file-size.pipe';
import {SideDecorationsComponent} from './components/side-decorations/side-decorations.component';
import {HasOrganizationDirective} from './directives/has-organization.directive';
import {FirstLetterToLoweCasePipe} from './pipes/first-letter-to-lower-case-pipe';
import {CurrentProfileResolver, SettingResolver} from './resolvers';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    PageHeaderComponent,
    DisplayFormItemComponent,
    MessageBoxComponent,
    FirstLetterToLoweCasePipe,
    FileSizePipe,
    HasOrganizationDirective,
    HasAdminDirective,
    SideDecorationsComponent
  ],
  providers   : [
    CurrentProfileResolver,
    SettingResolver,
    FileSizePipe,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    PageHeaderComponent,
    DisplayFormItemComponent,
    MessageBoxComponent,
    FirstLetterToLoweCasePipe,
    FileSizePipe,
    HasOrganizationDirective,
    HasAdminDirective,
    SideDecorationsComponent
  ]
})
export class SharedModule
{
}
