import {Component, Input} from '@angular/core';

@Component({
  selector   : 'msn-message-box',
  templateUrl: 'message-box.component.html'
})
export class MessageBoxComponent
{
  @Input() message: string;
}
