import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {ReceivedDelivery} from "../models/received-delivery.model";

@Injectable()
export class ReceivedDeliveryCollection extends BaseCollection<ReceivedDelivery>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'received-deliveries');
  }
}
