import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KeycloakConfig} from 'keycloak-js';
import {map} from 'rxjs/operators';
import {AuthorizationConfigurationTO} from '../to/authorization-configuration-to.model';

@Injectable()
export class AuthService
{
  constructor(private http: HttpClient)
  {
  }

  getAuthorizationConfiguration(): Promise<KeycloakConfig>
  {
    return this.http.get<AuthorizationConfigurationTO>('/api/authorization')
      .pipe(
        map(AuthService.toKeycloakConfig)
      )
      .toPromise();
  }

  private static toKeycloakConfig(authorizationConfigurationTO: AuthorizationConfigurationTO): KeycloakConfig {
    return {
      url: authorizationConfigurationTO.authServerUrl,
      realm: authorizationConfigurationTO.realm,
      clientId: authorizationConfigurationTO.clientId
    }
  }
}
