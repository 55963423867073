import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {first} from 'rxjs/operators';
import {ProfileService} from 'src/app/core/services';

@Injectable()
export class AuthenticationGuard extends KeycloakAuthGuard
{
  constructor(protected router: Router, protected keycloakService: KeycloakService, protected profileService: ProfileService, private zone: NgZone)
  {
    super(router, keycloakService);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>
  {
    if (!this.authenticated)
    {
      await this.keycloakAngular.login();
    }

    const profile = await this.profileService.getCurrent()
      .pipe(first()).toPromise();

    const isOnboardingPage = state.url.includes('onboarding');
    if (!profile.firstTimeUser && isOnboardingPage)
    {
      // adding zone.run fix issues with change detection and navigation
      this.zone.run(() => this.router.navigate(['../dashboard']));
    }
    else if (profile.firstTimeUser && !isOnboardingPage)
    {
      // adding zone.run fix issues with change detection and navigation
      this.zone.run(() => this.router.navigate(['/onboarding']));
    }

    return true;
  }
}
