import {DataType} from 'src/app/core/models/data-type.model';
import {MessageType} from 'src/app/core/models/message-type.model';

export class MessageTypeAttachment
{
  messageType: MessageType;
  dataType: DataType;
  minimumNumberOfAttachments: number;
  maximumNumberOfAttachments: number;
  active: boolean;
}
