import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {pairwise} from 'rxjs/operators';
import {MSNTranslationLoader} from 'src/app/core/initialization/msn-translation-loader.service';
import {Setting, SettingService} from 'src/app/core/services';
import {environment} from 'src/environments/environment';
import {LABEL} from 'src/app/core/constants';

@Injectable()
export class MSNInitializationService
{
  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2, private settingService: SettingService,
              private translateService: TranslateService, private translationLoader: MSNTranslationLoader)
  {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  load(): Promise<boolean>
  {
    this.watchTheme();

    this.settingService.updateSetting(Setting.LABEL, LABEL.DEFAULT);
    this.settingService.updateSetting(Setting.THEME, environment.defaultTheme);

    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.settingService.updateSetting(Setting.LANGUAGE, environment.defaultLanguage);

    return new Promise<boolean>(resolve =>
    {
      this.settingService.getSetting(Setting.LANGUAGE).subscribe(lang =>
      {
        this.translateService.use(lang);
        this.translationLoader.load(lang).then(() => resolve(true));
      });
    });
  }

  private watchTheme(): void
  {
    combineLatest([this.settingService.getSetting(Setting.LABEL), this.settingService.getSetting(Setting.THEME)])
      .pipe(pairwise())
      .subscribe(([oldSettings, newSettings]) =>
      {
        if (oldSettings && oldSettings[0])
        {
          const [oldLabel, oldTheme] = oldSettings;
          this.renderer.removeClass(this.document.body, `${oldLabel.key}--${oldTheme}`);
        }

        const [newLabel, newTheme] = newSettings;
        this.renderer.addClass(this.document.body, `${newLabel.key}--${newTheme}`);
      });
  }
}
