import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ErrorHandler} from 'src/app/core/error-handler';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {catchError} from 'rxjs/operators';
import {headers} from './collection.helper';

/**
 * A simple caching collection that does not use BaseEntity as its generic type.
 */
export abstract class SimpleCacheCollection<T>
{
  loading = false;
  private cacheCollection: BehaviorSubject<T[]>;

  protected constructor(protected http: HttpClient, protected errorHandler: ErrorHandler, protected name: string)
  {
    this.cacheCollection = new BehaviorSubject<T[]>([]);
  }

  cacheList(reload: boolean = false): Observable<T[]>
  {
    if ((this.cacheCollection.getValue().length === 0 && !this.loading) || reload)
    {
      this.list().subscribe();
    }

    return this.cacheCollection.asObservable();
  }

  list(): Observable<T[]>
  {
    this.loading = true;
    return this.http.get<T[]>(this.getBaseUrl(), {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<T[]>(err)))
      .pipe(
        tap(list => this.cacheCollection.next(list)),
        tap(() => this.loading = false),
      );
  }

  protected getBaseUrl(): string
  {
    return `${BaseCollection.URL_PREFIX}/${this.name}`;
  }
}
