export const TRANSLATIONS = [
  'admin',
  'app',
  'connect',
  'dashboard',
  'download',
  'filing',
  'server',
  'received-delivery',
];
