import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CacheCollection} from 'src/app/core/collection/cache.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {MessageType} from 'src/app/core/models';

@Injectable()
export class MessageTypeCollection extends CacheCollection<MessageType>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'message-types');
  }

  listParams(): {[param: string]: string | number | boolean}
  {
    // We alwawys use the same paramters. We are not interested in "active for third party", but are interested in the other two options.
    // Those options are always: active for organization = true, active for private person = false. Because when it is active for private persons, it is enforced to be also active for organizations, but not the other way around.
    // The options are hard-coded, because we do not let the backend filter; we filter ourselves.
    return {'activeForThirdParty': false, 'activeForOrganization': true, 'activeForPrivatePerson': false};
  }
}
