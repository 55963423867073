import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {SettingsCollection} from '../collection';
import {Settings} from '../models';

@Injectable()
export class SettingService
{
  private stateValues: Map<Setting, BehaviorSubject<any>> = new Map();

  constructor(private settingsCollection: SettingsCollection)
  {
  }

  updateSetting(key: Setting, value: any): void
  {
    if (!this.stateValues.has(key))
    {
      this.stateValues.set(key, new BehaviorSubject(value));
    }
    else
    {
      const state = this.stateValues.get(key);
      if (state)
      {
        state.next(value);
      }
    }
  }

  getSetting(key: Setting): Observable<any>
  {
    if (!this.stateValues.has(key))
    {
      this.stateValues.set(key, new BehaviorSubject(null));
    }
    const state = this.stateValues.get(key);
    return state ? state.asObservable() : of(null);
  }

  getPersistedSettings(): Observable<Settings>
  {
    return this.settingsCollection.cacheGet();
  }
}

export enum Setting
{
  LANGUAGE = 'lang',
  THEME = 'theme',
  LABEL = 'label'
}
