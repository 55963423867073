import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {Filing, FilingStatus} from 'src/app/core/models';
import {headers} from './collection.helper';

@Injectable()
export class FilingCollection extends BaseCollection<Filing>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'filings');
  }

  getStatusesByGuid(guid: string): Observable<FilingStatus[]>
  {
    const url = `${this.getDetailUrl(guid)}/statuses`;
    return this.http.get<FilingStatus[]>(url, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<FilingStatus[]>(err)));
  }
}
