import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Options} from 'angular2-notifications';

@Component({
  selector   : 'msn-root',
  templateUrl: 'app.component.html'
})
export class AppComponent
{
  notificationOptions: Options = {
    position       : ['top', 'right'],
    pauseOnHover   : true,
    timeOut        : 5000,
    lastOnBottom   : true,
    showProgressBar: false
  };

  constructor(private titleService: Title,
              private translateService: TranslateService)
  {
    titleService.setTitle(translateService.instant('app.title'));
  }
}
