import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {Attachment} from 'src/app/core/models';
import {Observable} from 'rxjs';
import {prepareBlobHeader} from './collection.helper';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AttachmentCollection extends BaseCollection<Attachment>
{
  constructor(http: HttpClient, errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'attachments');
  }

  download(guid: string, contentType: string): Observable<any>
  {
    const url = `${this.getDetailUrl(guid)}/data`;
    return this.http.get<any>(url, prepareBlobHeader(contentType))
      .pipe(catchError(err => this.errorHandler.handle<any>(err)));
  }
}
