import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {headers} from 'src/app/core/collection/collection.helper';
import {ErrorHandler} from 'src/app/core/error-handler';
import {FilingStatus} from 'src/app/core/models';
import {Application} from 'src/app/core/models/application.model';

@Injectable()
export class ApplicationCollection extends BaseCollection<Application>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'applications');
  }

  getLogo(applicationId: string)
  {
    const url = `${this.getDetailUrl(applicationId)}/logo`;
    return this.http.get<string>(url, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<FilingStatus[]>(err)));
  }
}
