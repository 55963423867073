import {Directive, ElementRef, OnInit} from '@angular/core';
import {filter, first} from 'rxjs/operators';
import {ProfileService} from '../../core';

/**
 * Directive for hiding parts of the application that require an organization. This will only hide
 * the element and not remove it like ngIf would
 */
@Directive({selector: '[hasOrganization]'})
export class HasOrganizationDirective implements OnInit
{
  constructor(private elementRef: ElementRef, private profileService: ProfileService)
  {
  }

  ngOnInit(): void
  {
    this.profileService.getCurrent()
      .pipe(
        first(),
        filter(profile => !profile.hasOrganization)
      )
      .subscribe(_ => this.elementRef.nativeElement.style.display = 'none');
  }
}
