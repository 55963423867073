<div class="imageloader-container">
    <div class="message ">
        <h1>{{'filing.htmlDownload.title' | translate}}</h1>
        <ng-container *ngIf="!downloadFailed; else showFailedMessage">
            <span>{{'filing.htmlDownload.subText' | translate}}</span>
            <div id="loader"></div>
        </ng-container>
    </div>
</div>

<ng-template #showFailedMessage>
    <span>
        <i class="fa fa-lg fa-exclamation-circle error icon-right"></i>
        {{'filing.htmlDownload.error' | translate}}
    </span>
</ng-template>
