import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DEFAULT_PAGE_CONSTANTS} from 'src/app/core/constants';
import {ErrorHandler} from 'src/app/core/error-handler';
import {BaseEntity} from 'src/app/core/models';
import {Page} from 'src/app/core/models/page.model';
import {headers} from './collection.helper';

export abstract class BaseCollection<T extends BaseEntity>
{
  public static readonly URL_PREFIX = '/api';

  protected constructor(protected http: HttpClient, protected errorHandler: ErrorHandler, protected name: string)
  {
  }

  private static toHttpParams(searchParams: Map<string, any>): HttpParams
  {
    let httpParams = new HttpParams();
    searchParams.forEach((value, key) =>
    {
      httpParams = httpParams.append(key, value);
    });
    return httpParams;
  }

  protected getBaseUrl(): string
  {
    return `${BaseCollection.URL_PREFIX}/${this.name}`;
  }

  protected getDetailUrl(guid: string): string
  {
    return `${this.getBaseUrl()}/${guid}`;
  }

  create(object: T): Observable<T>
  {
    const body = JSON.stringify(object);
    return this.http.post<T>(this.getBaseUrl(), body, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<T>(err)));
  }

  getByGuid(guid: string): Observable<T>
  {
    return this.http.get<T>(this.getDetailUrl(guid), {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<T>(err)));
  }

  update(object: T): Observable<T>
  {
    return this.updateByUrl(object, this.getDetailUrl(object.guid));
  }

  protected updateByUrl(object: T, url: string)
  {
    const body = JSON.stringify(object);
    return this.http.put<T>(url, body, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<T>(err)));
  }

  list(): Observable<T[]>
  {
    const params = new Set([{headers: headers()}, this.listParams()]);
    return this.http.get<T[]>(this.getBaseUrl(), {headers: headers(), params: this.listParams()})
      .pipe(catchError(err => this.errorHandler.handle<T[]>(err)));
  }

  /**
   * This provides a way to specify the default parameters to use when doing the `list()` call.
   */
  protected listParams(): HttpParams | {[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>}
  {
    return {};
  }

  page(page: number = DEFAULT_PAGE_CONSTANTS.PAGE, size: number = DEFAULT_PAGE_CONSTANTS.SIZE, searchParams: Map<string, any>): Observable<Page<T>>
  {
    let params = BaseCollection.toHttpParams(searchParams);
    params = params.set('page', String(page)).set('size', String(size));

    return this.http.get<Page<T>>(this.getBaseUrl(), {headers: headers(), params})
      .pipe(catchError(err => this.errorHandler.handle<Page<T>>(err)));
  }

  delete(guid: string): Observable<void>
  {
    return this.http.delete<any>(this.getDetailUrl(guid), {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<void>(err)));
  }
}
