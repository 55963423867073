import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BaseCollection} from 'src/app/core/collection/base.collection';
import {prepareHeader} from 'src/app/core/collection/collection.helper';
import {ErrorHandler} from 'src/app/core/error-handler';
import {Report} from 'src/app/core/models';
import {DownloadType} from 'src/app/core/models/download-type.model';

@Injectable()
export class ReportCollection extends BaseCollection<Report>
{
  constructor(http: HttpClient, errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'reports');
  }

  downloadReport(guid: string, downloadType: DownloadType): Observable<any>
  {
    const url = `${this.getDetailUrl(guid)}/${downloadType.path}`;
    return this.http.get<any>(url, prepareHeader(downloadType.mediaType))
      .pipe(catchError(err => this.errorHandler.handle<any>(err)));
  }
}
