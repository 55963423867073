import {Organization} from './organization.model';
import {VersionedBaseEntity} from './versioned-base-entity.model';

export class Profile extends VersionedBaseEntity
{
  firstName: string;
  familyName: string;
  email: string;
  receiveUpdates: boolean;
  organization?: Organization;
  firstTimeUser: boolean;
}
