export class ProfileTO
{
  firstName: string;
  familyName: string;
  fullName: string;
  email: string;
  receiveUpdates: boolean;
  hasOrganization: boolean;
  organizationName?: string;
  firstTimeUser: boolean;
}
