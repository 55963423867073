import {Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {first, map} from 'rxjs/operators';
import {ProfileService, ProfileTO, SettingService} from 'src/app/core';
import {environment} from 'src/environments/environment';

@Component({
  selector   : 'msn-main-menu',
  templateUrl: 'main-menu.component.html'
})
export class MainMenuComponent implements OnInit
{
  profile: ProfileTO;
  menuExpanded = false;

  constructor(private profileService: ProfileService, private keycloakService: KeycloakService, private settingService: SettingService)
  {
  }

  ngOnInit(): void
  {
    this.profileService.getCurrent()
      .pipe(first())
      .subscribe(profile => this.profile = profile);
  }

  logOut(): void
  {
    this.settingService.getPersistedSettings()
      .pipe(
        map(settings => settings.keycloakLogoutRedirectUri)
      )
      .subscribe(redirectUri => this.keycloakService.logout(redirectUri));
  }

  closeMenu(): void
  {
    if (this.menuExpanded)
    {
      this.menuExpanded = false;
    }
  }
}
