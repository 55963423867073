import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ReportService} from 'src/app/core';
import {DownloadType} from 'src/app/core/models/download-type.model';
import {MediaType} from 'src/app/core/models/media-type.model';

@Component({
  selector: 'msn-html-rendering-download-page',
  templateUrl: './html-rendering-download-page.component.html',
  styleUrls: ['./html-rendering-download-page.component.scss']
})
export class HtmlRenderingDownloadPageComponent implements OnInit
{
  downloadOption: DownloadType = {path: 'render', mediaType: MediaType.HTML, keyLabel: '', keyTooltip: 'htmlDownload'}
  downloadFailed = false;
  private fileGuid: string;

  constructor(private reportService: ReportService,
              private route: ActivatedRoute)
  {
  }

  ngOnInit(): void
  {
    this.fileGuid = this.route.snapshot.params.guid;
    this.initDownload();
  }

  initDownload(): void
  {
    this.reportService.downloadReport(this.fileGuid, this.downloadOption)
      .pipe(catchError(() => {
        this.downloadFailed = true;
        return EMPTY;
      }))
      .subscribe(html => this.openHtmlInCurrentWindow(html));
  }

  private openHtmlInCurrentWindow(htmlFile: Blob): void
  {
    window.location.href = URL.createObjectURL(htmlFile);
  }
}
