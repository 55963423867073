import {Component} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  templateUrl: 'biv-portal.component.html'
})
export class BivPortalComponent
{
  eHerkenningSuppliersLink = environment.eHerkenningSuppliersLink;
}
