import {animate, group, state, style, transition, trigger} from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import {filter, first} from 'rxjs/operators';
import {SettingService} from 'src/app/core/services';

@Component({
  templateUrl: 'navigation-container.component.html',
  animations : [
    trigger('sideNavigationState', [
      state('normal', style({
        transform: 'translateX(0)',
        opacity  : 1
      })),
      transition('void => *', [
        group([
          style({
            opacity  : 0,
            transform: 'translateX(-500px)'
          }),
          animate('300ms ease-out', style({
            transform: 'translateX(0px)'
          })),
          animate('400ms ease-out', style({
            opacity: 1
          }))
        ])
      ])
    ]),
    trigger('contentState', [
      state('normal', style({
        opacity: 1
      })),
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate('400ms ease-out', style({
          opacity: 1
        }))
      ])
    ])
  ]
})
export class NavigationContainerComponent implements OnInit
{
  constructor(private settingService: SettingService)
  {
  }

  ngOnInit(): void
  {
    this.settingService.getPersistedSettings()
      .pipe(
        filter(setting => !!setting.zendeskUrl),
        first()
      )
      .subscribe(setting => NavigationContainerComponent.loadExternalScript('ze-snippet', setting.zendeskUrl));
  }

  private static loadExternalScript(id: string, url: string): void
  {
    const zeSnippetScriptLoaded = document.getElementById('ze-snippet');
    if (!zeSnippetScriptLoaded)
    {
      const body = document.body as HTMLDivElement;
      const script = document.createElement('script');
      script.innerHTML = '';
      script.id = id;
      script.src = url;
      body.appendChild(script);
    }
  }
}
