import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ErrorHandler} from '../error-handler';
import {Profile} from '../models';
import {CacheCollection} from './cache.collection';

@Injectable()
export class ProfileCollection extends CacheCollection<Profile>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'profiles');
  }

  updateCurrent(profile: Profile): Observable<Profile>
  {
    return this.updateByUrl(profile, this.getDetailUrl('current'));
  }
}
