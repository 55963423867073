import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {Settings, SettingService} from '../../core';

@Injectable()
export class SettingResolver implements Resolve<Settings>
{
  constructor(private settingService: SettingService)
  {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Settings>
  {
    return this.settingService.getPersistedSettings().pipe(first());
  }
}
