import {HttpHeaders} from '@angular/common/http';
import {MediaType} from 'src/app/core/models/media-type.model';

export function headers(): HttpHeaders
{
  return new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
}

export function prepareHeader(mediaType: MediaType): {}
{
  return prepareBlobHeader(mediaType);
}

export function prepareBlobHeader(contentType: string): {}
{
  return {
    headers     : new HttpHeaders({'Content-Type': contentType + '; charset=utf-8'}),
    responseType: 'blob'
  };
}
