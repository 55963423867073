import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {ApiKeyCollection, ApplicationCollection, AttachmentCollection, MessageTypeCollection, ProfileCollection, ReportCollection, SupportedMessageTypeCollection} from 'src/app/core/collection';
import {FilingCollection} from 'src/app/core/collection/filing.collection';
import {SettingsCollection} from 'src/app/core/collection/settings.collection';
import {ReceivedDeliveryCollection} from './received-delivery.collection';
import {ReceiverSbvRequestConfigurationCollection} from './receiver-sbv-request-configuration.collection';
import {AvailableSbvRequestConsentTypesCollection} from 'src/app/core/collection/available-sbv-request-consent-types.collection';

@NgModule({
  declarations: [],
  imports     : [
    HttpClientModule,
  ],
  providers   : [
    ApiKeyCollection,
    ApplicationCollection,
    AvailableSbvRequestConsentTypesCollection,
    AttachmentCollection,
    FilingCollection,
    ReceivedDeliveryCollection,
    MessageTypeCollection,
    ProfileCollection,
    ReceiverSbvRequestConfigurationCollection,
    ReportCollection,
    SettingsCollection,
    SupportedMessageTypeCollection,
  ]
})
export class CollectionModule
{
}
