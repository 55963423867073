import {BaseEntity} from 'src/app/core/models/base-entity.model';
import {MessageTypeAttachment} from 'src/app/core/models/message-type-attachment.model';
import {MessageTypeEntryPoint} from './message-type-entry-point.model';
import {MessageTypeReceiver} from './message-type-receiver.model';

export class MessageType extends BaseEntity
{
  name: string;
  description: string;
  maximumNumberOfAttachments: number;
  activeForPrivatePerson: boolean;
  activeForOrganization: boolean;
  allowedReceivers: MessageTypeReceiver[];
  allowedEntryPoints: MessageTypeEntryPoint[];
  allowedAttachments: MessageTypeAttachment[];
  active: boolean;
}
