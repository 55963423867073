import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CacheCollection} from 'src/app/core/collection/cache.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {ApiKey} from 'src/app/core/models/api-key.model';
import {headers} from './collection.helper';

@Injectable()
export class ApiKeyCollection extends CacheCollection<ApiKey>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'api-keys');
  }

  createApiKey(apiKey: ApiKey): Observable<ApiKey>
  {
    return this.http.post<ApiKey>(this.getBaseUrl(), apiKey, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<ApiKey>(err)));
  }
}
