<div *ngIf="profile" class="onboarding-page" fxLayout="column" fxLayoutAlign="center center">
    <msn-logo></msn-logo>
    <div class="text-box" fxLayout="row wrap">
        <h3 fxFlex="100" class="header">
            {{'app.welcome.title' | translate : {user: profile.fullName} }}
        </h3>
        <p fxFlex="100" class="content">
            {{'app.welcome.onboarding.firstTimeContent' | translate: {user: profile.fullName} }}
        </p>
        <mat-checkbox [(ngModel)]="receiveUpdates">
            {{'app.welcome.onboarding.receiveUpdates' | translate}}
        </mat-checkbox>
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-end start">
            <button color="primary" mat-flat-button (click)="save(profile)">
                {{'app.action.next' | translate}}
            </button>
        </div>
    </div>
</div>

