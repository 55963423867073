import {Directive, ElementRef, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';

/**
 * Directive for hiding parts of the application that require the admin role. This will only hide
 * the element and not remove it like ngIf would
 */
@Directive({selector: '[hasAdmin]'})
export class HasAdminDirective implements OnInit
{
  constructor(private elementRef: ElementRef, private keycloakService: KeycloakService)
  {
  }

  ngOnInit(): void
  {
    if (!this.keycloakService.isUserInRole('admin'))
    {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
