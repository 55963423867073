import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {ProfileService, ProfileTO} from '../../core';

@Injectable()
export class CurrentProfileResolver implements Resolve<ProfileTO>
{
  constructor(private profileService: ProfileService)
  {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfileTO>
  {
    return this.profileService.getCurrent().pipe(first());
  }
}
