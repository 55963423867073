import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {first} from 'rxjs/operators';
import {ProfileService} from 'src/app/core/services';
import {ProfileTO} from 'src/app/core/to';

@Component({
  templateUrl: 'onboarding.component.html'
})
export class OnboardingComponent implements OnInit
{
  profile: ProfileTO;
  receiveUpdates = false;

  constructor(private profileService: ProfileService,
              private router: Router)
  {
  }

  ngOnInit(): void
  {
    const data$ = combineLatest([
      this.profileService.getCurrent().pipe(first())
    ]);

    data$.subscribe(([profile]) =>
    {
      this.profile = profile;
    });
  }

  save(profile: ProfileTO): void
  {
    profile.receiveUpdates = profile.firstTimeUser ? this.receiveUpdates : profile.receiveUpdates;

    this.profileService.update(profile)
      .subscribe(() => this.router.navigate(['../dashboard']));
  }
}
