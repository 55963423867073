import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CacheCollection} from 'src/app/core/collection/cache.collection';
import {ErrorHandler} from 'src/app/core/error-handler';
import {ReceiverSbvRequestConfiguration} from 'src/app/core/models';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {headers} from './collection.helper';

@Injectable()
export class ReceiverSbvRequestConfigurationCollection extends CacheCollection<ReceiverSbvRequestConfiguration>
{
  constructor(protected http: HttpClient, protected errorHandler: ErrorHandler)
  {
    super(http, errorHandler, 'receiver-sbv-request-configuration');
  }

  create(receiverSbvRequestConfiguration: ReceiverSbvRequestConfiguration): Observable<ReceiverSbvRequestConfiguration>
  {
    const body = JSON.stringify(receiverSbvRequestConfiguration);
    return this.http.post<ReceiverSbvRequestConfiguration>(this.getDetailUrl(receiverSbvRequestConfiguration.receiver.guid), body, {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<ReceiverSbvRequestConfiguration>(err)));
  }

  update(receiverSbvRequestConfiguration: ReceiverSbvRequestConfiguration): Observable<ReceiverSbvRequestConfiguration>
  {
    return this.updateByUrl(receiverSbvRequestConfiguration, this.getDetailUrl(receiverSbvRequestConfiguration.receiver.guid));
  }

  delete(receiverGuid: string): Observable<void>
  {
    return this.http.delete<any>(this.getDetailUrl(receiverGuid), {headers: headers()})
      .pipe(catchError(err => this.errorHandler.handle<void>(err)));
  }
}
