import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATIONS} from 'src/app/core/initialization/translation-constants';

@Injectable()
export class MSNTranslationLoader
{
  private loadedModuleTranslations = {};

  constructor(private http: HttpClient, private translate: TranslateService)
  {
  }

  load(lang: string): Promise<boolean[]>
  {
    const promises = TRANSLATIONS.map(module => this.loadForModule(module, lang));
    return Promise.all(promises);
  }

  loadForModule(module, lang): Promise<boolean>
  {
    if (!this.loadedModuleTranslations[module])
    {
      this.loadedModuleTranslations[module] = {};
    }
    if (!this.loadedModuleTranslations[module][lang])
    {
      return this.http.get(`/assets/i18n/${module}/${lang}.json`).toPromise().then(
        res =>
        {
          this.loadedModuleTranslations[module][lang] = res;
          this.translate.setTranslation(lang, res, true);
          return true;
        });
    }
    else
    {
      return Promise.resolve(true);
    }
  }
}
