<h2 mat-dialog-title>{{data.title | translate: {name: data.param} }}</h2>
<mat-dialog-content>
    <p [innerHTML]="data.content | translate: {name: data.param}"></p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
    <div fxFlex="1 1 auto" fxFlexAlign="start">
        <a mat-stroked-button color="primary" mat-dialog-close (click)="dialogRef.close()">{{'app.action.cancel' | translate}}</a>
    </div>
    <div fxFlex="0 0 auto" fxFlexAlign="end">
        <button mat-flat-button
                [color]="!data.deleteAction ? 'primary' : null"
                [class.error]="data.deleteAction"
                (click)="dialogRef.close(true)">
            {{data.confirm | translate}}
        </button>
    </div>
</mat-dialog-actions>
