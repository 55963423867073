import {Attachment} from 'src/app/core/models/attachment.model';
import {Profile} from './profile.model';
import {Report} from './report.model';
import {StatusType} from './status-type.model';
import {VersionedBaseEntity} from './versioned-base-entity.model';
import {Receiver} from './receiver.model';

export class Filing extends VersionedBaseEntity
{
  filingReference?: string;
  statusCode: string;
  statusType: StatusType;
  messageTypeGuid: string;
  receiver: Receiver;
  report: Report;
  createdByProfile?: Profile;
  createdBySupplier?: string;
  sentAt?: string;
  externalReference?: string;
  errorCode?: string;
  errorDescription?: string;
  attachments: Attachment[] = [];
}
