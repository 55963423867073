import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationData} from './confirmation-data.model';

@Component({
  templateUrl: 'confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent
{
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationData)
  {
  }
}
