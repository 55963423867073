import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

/**
 * Based on: https://stackoverflow.com/a/51059505/4565299
 */
@Injectable()
export class RedirectGuard implements CanActivate
{
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean
  {
    window.location.href = route.data['externalUrl'];
    return true;
  }
}
